<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Order Details - #{{ orderDetails.order_reference }}</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Shop Name</label
                    >
                    <v-text-field
                      disabled
                      outlined
                      placeholder="Shop Name"
                      v-model="shopName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Shop Owner</label
                    >
                    <v-text-field
                      disabled
                      outlined
                      placeholder="Shop Owner"
                      v-model="shopOwnerName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Payment Type</label
                    >

                    <v-select
                      :disabled="!canOrderBeModified || !disablePaymentType"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Choose Payment Type"
                      :items="orderPaymentTypes"
                      item-text="value"
                      item-value="key"
                      v-model="orderPaymentType"
                      :error-messages="
                        getFieldValidationErrors(v$.orderPaymentType.$errors)
                      "
                      @blur="v$.orderPaymentType.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Order Status</label
                    >
                    <v-select
                      :disabled="!canOrderBeModified"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Order Status"
                      :items="adjustedMutationOrderStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="orderStatus"
                      :error-messages="
                        getFieldValidationErrors(v$.orderStatus.$errors)
                      "
                      @blur="v$.orderStatus.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Order Packer</label
                    >
                    <v-select
                      :disabled="!canOrderBeModified || !disableOrderPacker"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Choose Packer"
                      :items="packers"
                      item-text="name"
                      item-value="id"
                      v-model="orderPacker"
                      :error-messages="
                        getFieldValidationErrors(v$.orderPacker.$errors)
                      "
                      @blur="v$.orderPacker.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <v-checkbox
                      disabled
                      v-model="isCreditOrder"
                      label="Is Credit Order"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Delivery Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Address</label
                    >
                    <v-select
                      :disabled="!disableDeliveryAddress"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Delivery Address"
                      :items="shopAddresses"
                      item-text="address_line_1"
                      item-value="id"
                      v-model="shopAddressId"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.shopAddressId.$errors)
                      "
                      @blur="v$.shopAddressId.$touch"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Date</label
                    >

                    <v-menu
                      :disabled="!disableDeliveryDate"
                      ref="deliveryDateMenu"
                      v-model="deliveryDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="!disableDeliveryDate"
                          outlined
                          dense
                          v-model="deliveryDate"
                          placeholder="Select delivery date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(v$.deliveryDate.$errors)
                          "
                          @input="v$.deliveryDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deliveryDate"
                        @input="deliveryDateMenu = false"
                        :min="getMinDeliveryDate()"
                        :max="getMaxDeliveryDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Status</label
                    >
                    <v-select
                      disabled
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Delivery Status"
                      :items="deliveryStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="deliveryStatus"
                      :error-messages="
                        getFieldValidationErrors(v$.deliveryStatus.$errors)
                      "
                      @input="v$.deliveryStatus.$touch"
                    >
                    </v-select>
                  </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Products</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row v-if="showProductSearch">
                  <v-col cols="12" md="12" class="py-0">
                    <v-autocomplete
                      :disabled="!canOrderBeModified || !canProductsBeSearched"
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="searchProduct.value"
                      :loading="searchProduct.isLoading"
                      :items="searchedProductList"
                      item-text="friendlyName"
                      item-value="id"
                      :search-input.sync="searchProduct.sync"
                      flat
                      cache-items
                      label="Start typing to search a product.."
                      hint="Start typing to search a product..."
                      :hide-no-data="true"
                      solo
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mt-10 mb-2">
                  <v-col v-if="orderProducts.length == 0">
                    <p>This order has no products.</p>
                  </v-col>
                  <template v-else>
                    <v-col cols="12 mb-3" md="12" class="py-0">
                      <strong>Order Total: R {{ this.getOrderTotal }}</strong>
                    </v-col>
                    <v-col
                      v-for="(product, index) in orderProducts"
                      :key="index"
                      cols="12"
                      md="3"
                      class="py-0"
                    >
                      <v-card class="card-shadow mb-6">
                        <v-img
                          height="200"
                          :src="getProductPrimaryImageUri(product)"
                        >
                        </v-img>
                        <v-card-text
                          class="card-padding"
                          :style="getCardTextStyle"
                        >
                          <h2 class="text-h2 text-typo font-weight-600 mb-0">
                            <v-avatar color="#52c3c3" size="30">
                              <small>{{ product.pivot.quantity || 0 }}</small>
                            </v-avatar>
                            {{ product.name }} {{ product.weight }} x {{ product.package_units }}
                          </h2>
                          <p></p>
                          <strong>
                            Unit Price: R {{ product.retail_price }} <br />
                            Total: R
                            {{ product.retail_price * product.pivot.quantity }}
                          </strong>
                          <div class="mt-5" style="text-align: center">
                            <v-icon
                              :disabled="productModificationsDisabled"
                              @click="decreaseQuantity(product)"
                              large
                            >
                              ni ni-fat-delete
                            </v-icon>
                            <v-icon
                              :disabled="productModificationsDisabled"
                              @click="increaseQuantity(product)"
                              large
                            >
                              ni ni-fat-add
                            </v-icon>
                          </div>
                          <div v-if="originalOrderStatus == 'packaging'">
                            <v-switch
                              v-model="product.pivot.is_unavailable"
                              label="Product Unavailable"
                              color="red"
                              hide-details
                            >
                            </v-switch>
                          </div>
                          <div
                            v-if="
                              originalOrderStatus ==
                                'alternatives_and_replacements' ||
                              originalOrderStatus ==
                                'awaiting_replacement_confirmation'
                            "
                            class="mt-4"
                          >
                            <v-btn
                              v-if="product.pivot.is_unavailable"
                              :disabled="
                                orderStatus != 'alternatives_and_replacements'
                              "
                              block
                              @click="
                                showChooseAlternativeProductDialog(product)
                              "
                              elevation="0"
                              :ripple="false"
                              height="43"
                              :loading="submitButton.isLoading"
                              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                              color="#fb6340"
                              >Choose Replacement</v-btn
                            >
                            <v-row
                              v-if="product.pivot.replacement_set"
                              class="mt-1"
                            >
                              <v-col cols="12" style="text-align: center">
                                <strong>Replacement Product</strong>
                              </v-col>
                              <v-col cols="4">Name:</v-col>
                              <v-col cols="8">{{
                                product.pivot.fullProduct.name
                              }}</v-col>
                              <v-col cols="4">Quantity:</v-col>
                              <v-col cols="8">{{
                                product.pivot.replacement_quantity
                              }}</v-col>
                              <v-col cols="4">Unit Price:</v-col>
                              <v-col cols="8">
                                R{{
                                  parseFloat(
                                    product.pivot.replacement_retail_price
                                  ).toFixed(2)
                                }}
                              </v-col>
                              <v-col cols="4">Total:</v-col>
                              <v-col cols="8">
                                R{{
                                  parseFloat(
                                    product.pivot.replacement_retail_price *
                                      product.pivot.replacement_quantity
                                  ).toFixed(2)
                                }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
                <v-btn
                  v-if="showUpdateButton"
                  :disabled="!canOrderBeModified"
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Update</v-btn
                >
                <!-- <v-btn
                  v-if="orderStatus == 'packaging'"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Missing Products</v-btn
                > -->
                <v-row class="mt-1">
                  <v-col cols="12">
                    <div class="ml-3">
                      <h4>Order Notes</h4>
                      <div class="ml-5 mt-3" v-if="orderNotes.length > 0">
                        <span
                          v-for="(note, index) in orderNotes"
                          :key="`note-${index}`"
                        >
                          <p>
                            <span style="color: #ff0000">
                              {{ getNotePrefix(note) }}</span
                            >: {{ note.text }}
                          </p>
                        </span>
                      </div>
                      <div v-else>There are no notes for this order.</div>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialogChooseAlternativeProduct" max-width="500px">
        <v-card class="card-shadow card-padding">
          <v-card-title class="pt-0 text-h4 text-typo justify-center"
            >Choose Alternative Product</v-card-title
          >
          <v-card-text>
            <v-autocomplete
              class="input-style font-size-input text-light-input placeholder-light"
              v-model="searchAlternativeProduct.value"
              :loading="searchAlternativeProduct.isLoading"
              :items="searchedProductList"
              item-text="name"
              item-value="id"
              :search-input.sync="searchAlternativeProduct.sync"
              flat
              cache-items
              label="Start typing to search a product.."
              hint="Start typing to search a product..."
              :hide-no-data="true"
              solo
            ></v-autocomplete>
            <v-text-field
              v-model="searchAlternativeProduct.quantity"
              outlined
              class="input-style font-size-input text-light-input placeholder-light"
              placeholder="Quantity"
              type="number"
              min="1"
            ></v-text-field>
            Unit Price: R{{ getAlternativeProductUnitCost }}<br />
            Total: R{{ getAlternativeProductTotalCost }}
          </v-card-text>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeChooseAlternativeProduct"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
              color="#f5365c"
              >Cancel</v-btn
            >

            <v-btn
              @click="chooseProductReplacement"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
              color="#5e72e4"
              >Choose</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import debounce from "debounce";
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "orders-edit",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      orderStatus: { required },
      deliveryDate: { required },
      deliveryStatus: { required },
      shopAddressId: { required },
      orderPacker: {
        requiredIfStatus: requiredIf(
          [
            "packaging",
            "packaging",
            "alternatives_and_replacements",
            "awaiting_payment",
          ].includes(this.orderStatus)
        ),
      },
      orderPaymentType: { required },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allSearchedProducts: [],
      deliveryAddress: "N/A",
      deliveryDate: "",
      deliveryDateMenu: null,
      deliveryStatus: "draft",
      dialogChooseAlternativeProduct: false,
      originalDeliveryStatus: "draft",
      orderDetails: {},
      orderPacker: null,
      orderPaymentType: "",
      orderProducts: [],
      orderStatus: "draft",
      orderNotes: [],
      originalOrderStatus: "draft",
      packers: [],
      productBeingReplaced: null,
      searchProduct: {
        value: "",
        isLoading: false,
        selected: null,
        sync: null,
      },
      searchAlternativeProduct: {
        value: "",
        isLoading: false,
        quantity: 1,
        retailPrice: "0.00",
        selected: null,
        sync: null,
      },
      searchedProductList: [],
      shopAddressId: "",
      shopAddresses: [],
      shopName: "",
      shopOwnerName: "",
      submitButton: {
        isLoading: false,
      },
      isCreditOrder: false,
    };
  },
  computed: {
    ...mapStores(useSessionStore),

    adjustedMutationOrderStatuses() {
      if (
        this.sessionStore.user.roles &&
        this.sessionStore.user.roles.includes("packer")
      ) {
        if (this.orderPaymentType === "cash_on_delivery") {
          return [
            {
              key: "packaging",
              value: "Packaging",
            },
            {
              key: "alternatives_and_replacements",
              value: "Alternatives And Replacements",
            },
            {
              key: "awaiting_replacement_confirmation",
              value: "Awaiting Replacement Confirmation",
            },
            {
              key: "review_and_assign_driver",
              value: "Ready for Delivery",
            },
          ];
        } else {
          return [
            {
              key: "packaging",
              value: "Packaging",
            },
            {
              key: "alternatives_and_replacements",
              value: "Alternatives And Replacements",
            },
            {
              key: "awaiting_replacement_confirmation",
              value: "Awaiting Replacement Confirmation",
            },
            {
              key: "awaiting_payment",
              value: "Awaiting Payment",
            },
            {
              key: "review_and_assign_driver",
              value: "Ready for Delivery",
            },
          ];
        }
      }

      const result = [...this.mutationOrderStatuses];

      const index = this.mutationOrderStatuses.findIndex(
        (item) => item.key === this.originalOrderStatus
      );

      result.splice(0, index);

      // If order payment type is COD, remove awaiting payment status...
      if (this.orderPaymentType === "cash_on_delivery") {
        const index2 = result.findIndex(
          (item) => item.key === "awaiting_payment"
        );

        if (index2 !== -1) {
          result.splice(index2, 1);
        }
      }

      return result;
    },

    canOrderBeModified() {
      if (
        ![
          "review_and_assign_driver",
          "in_transit",
          "delivered",
          "delivery_rescheduled",
        ].includes(this.originalOrderStatus)
      ) {
        return true;
      }

      return false;
    },

    canProductsBeSearched() {
      if (
        ["new", "packaging", "alternatives_and_replacements"].includes(
          this.originalOrderStatus
        )
      ) {
        return true;
      }

      return false;
    },

    canDeliveryBeModified() {
      if (!["delivered"].includes(this.originalDeliveryStatus)) {
        return true;
      }

      return false;
    },

    disableDeliveryAddress() {
      if (this.sessionStore.user.roles) {
        return (
          this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin")
        );
      }

      return false;
    },

    disableDeliveryDate() {
      if (this.sessionStore.user.roles) {
        return (
          this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("warehouse_manager")
        );
      }

      return false;
    },

    disableOrderPacker() {
      if (this.sessionStore.user.roles) {
        return (
          this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("warehouse_manager")
        );
      }

      return false;
    },

    disablePaymentType() {
      if (this.sessionStore.user.roles) {
        return (
          this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin")
        );
      }

      return false;
    },

    getAlternativeProductUnitCost() {
      const replacementProduct = this.allSearchedProducts.find(
        (e) => e.id == this.searchAlternativeProduct.value
      );

      let cost = 0;
      if (replacementProduct) {
        cost = parseFloat(replacementProduct.retail_price);
      }

      return cost.toFixed(2);
    },

    getAlternativeProductTotalCost() {
      const replacementProduct = this.allSearchedProducts.find(
        (e) => e.id == this.searchAlternativeProduct.value
      );

      let total = 0;
      if (replacementProduct) {
        total =
          parseFloat(replacementProduct.retail_price) *
          this.searchAlternativeProduct.quantity;
      }

      return total.toFixed(2);
    },

    getCardTextStyle() {
      if (this.orderStatus === "alternatives_and_replacements") {
        return {
          "min-height": "253px",
        };
      }

      return {};
    },

    getOrderTotal() {
      let total = 0;
      for (let i = 0; i < this.orderProducts.length; i += 1) {
        if (this.orderProducts[i].pivot.replacement_set) {
          total +=
            this.orderProducts[i].pivot.replacement_retail_price *
            this.orderProducts[i].pivot.replacement_quantity;
        } else {
          total +=
            this.orderProducts[i].retail_price *
            this.orderProducts[i].pivot.quantity;
        }
      }

      return total.toFixed(2);
    },

    productModificationsDisabled() {
      if (
        [
          "awaiting_replacement_confirmation",
          "awaiting_payment",
          "review_and_assign_driver",
          "awaiting_delivery",
          "in_transit",
          "delivered",
          "delivery_rescheduled",
          "cancelled",
        ].includes(this.orderStatus)
      ) {
        return true;
      }

      return false;
    },

    showProductSearch() {
      return [
        // "packaging",
        "alternatives_and_replacements",
        // "awaiting_replacement_confirmation",
      ].includes(this.originalOrderStatus);
    },

    showUpdateButton() {
      if (
        this.sessionStore.user.roles &&
        (this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("warehouse_manager") ||
          this.sessionStore.user.roles.includes("packer"))
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    closeChooseAlternativeProduct() {
      this.dialogChooseAlternativeProduct = false;
    },

    chooseProductReplacement() {
      if (!this.searchAlternativeProduct.value) {
        this.$notify.error("Please specify a product first.");
        return;
      }

      if (this.searchAlternativeProduct.quantity <= 0) {
        this.$notify.error("Product quantity must be at least 1");
        return;
      }

      const replacementProduct = this.allSearchedProducts.find(
        (e) => e.id == this.searchAlternativeProduct.value
      );

      const originalProduct = this.orderProducts.find(
        (e) => e.id == this.productBeingReplaced.id
      );

      if (replacementProduct) {
        originalProduct.pivot.fullProduct = replacementProduct;
        originalProduct.pivot.replacement_set = true;
        originalProduct.pivot.replacement_quantity = this.searchAlternativeProduct.quantity;
        originalProduct.pivot.replacement_retail_price =
          replacementProduct.retail_price;
        originalProduct.pivot.replacement_product_id = replacementProduct.id;
      } else {
        this.$notify.error(this.globalMessages.unexpectedError);
      }

      this.closeChooseAlternativeProduct();
    },

    decreaseQuantity(product) {
      if (product.pivot.quantity == 1) {
        for (var i = this.orderProducts.length - 1; i >= 0; --i) {
          if (this.orderProducts[i].id == product.id) {
            this.orderProducts.splice(i, 1);
            break;
          }
        }
      } else {
        product.pivot.quantity--;
      }
    },

    fetchPackers() {
      const body = [
        {
          field: "role",
          operator: "=",
          value: "packer",
        },
      ];

      this.$http
        .post("/users/search", body, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.packers.splice(0);
          for (let i = 0; i < result.data.data.length; i++) {
            this.packers.push({
              id: result.data.data[i].id,
              name: `${result.data.data[i].firstname} ${result.data.data[i].lastname}`,
            });
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            `Unable to fetch packers. Please refresh or contact support if the error persists.`
          );
        });
    },

    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    getProductPrimaryImageUri(product) {
      let primaryImageUri = this.defaultImage;

      const primaryImage = product.images.find(
        (e) => e.designation == "primary"
      );

      if (primaryImage) {
        primaryImageUri = `${this.sessionStore.getApiHost}${primaryImage.image_uri}`;
      }

      return primaryImageUri;
    },

    increaseQuantity(product) {
      product.pivot.quantity++;
    },

    searchProductApi: debounce(function (val) {
      const searchBody = [
        {
          field: "name",
          operator: "=",
          value: val,
        },
      ];

      this.searchedProductList.splice(0);
      this.$http
        .post("products/search", searchBody, this.sessionStore.getHttpConfig)
        .then((result) => {
          for (let i = 0; i < result.data.data.length; i += 1) {
            const tmpObj = result.data.data[i];
            tmpObj.quantity = 1;
            tmpObj.pivot = {
              is_unavailable: false,
            };

            tmpObj.friendlyName = `${tmpObj.name} ${tmpObj.weight} x ${tmpObj.package_units}`;
            this.searchedProductList.push(tmpObj);
            this.allSearchedProducts.push(tmpObj);
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while searching products. Please try again later or contact support."
          );
        });
    }, 500),

    showChooseAlternativeProductDialog(product) {
      this.productBeingReplaced = product;
      this.dialogChooseAlternativeProduct = true;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const orderBody = {
        address_detail: this.deliveryAddress,
        address_detail_id: this.shopAddressId,
        status: this.orderStatus,
        delivery: {
          delivery_date: this.deliveryDate,
          status: this.deliveryStatus,
        },
        delivery_date: this.deliveryDate,
        products: [...this.orderProducts],
        total: this.getOrderTotal,
        packer: this.orderPacker,
        payment_type: this.orderPaymentType,
      };

      this.$http
        .patch(`/orders/${this.id}`, orderBody, this.sessionStore.getHttpConfig)
        .then(async () => {
          this.$notify.success("Order updated successfully!");
          this.$router.push("/orders");
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while updating the order. Please try again later or contact support."
          );
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },

    // async submitReadyForDelivery() {
    //   const isFormCorrect = await this.v$.$validate();

    //   if (!isFormCorrect) {
    //     this.$notify.warning(
    //       "Please resolve the validation errors before proceeding!"
    //     );
    //     return;
    //   }

    //   this.submitButton.isLoading = true;

    //   const orderBody = {
    //     status: "review_and_assign_driver",
    //   };

    //   this.$http
    //     .patch(`/orders/${this.id}`, orderBody, this.sessionStore.getHttpConfig)
    //     .then(async () => {
    //       this.$notify.success("Order updated successfully!");
    //     })
    //     .catch((error) => {
    //       this.$notify.error(
    //         "An error occurred while updating the order. Please try again later or contact support."
    //       );
    //       console.error("Error while updating order: ", error);
    //     })
    //     .finally(() => {
    //       this.submitButton.isLoading = false;
    //     });
    // },
  },
  mounted() {
    this.$http
      .get(`/orders/${this.id}`, this.sessionStore.getHttpConfig)
      .then(async (result) => {
        const order = result.data.data[0];

        this.shopName = order.shop.name;
        this.shopOwnerName = "N/A";
        if (order.shop.owners && order.shop.owners.length > 0) {
          this.shopOwnerName = `${order.shop.owners[0].firstname} ${order.shop.owners[0].lastname}`;
        }

        this.orderPaymentType = order.payment_type;

        this.orderStatus = order.status;
        this.originalOrderStatus = order.status;

        this.shopAddressId = order.address_detail_id;

        if (order.packer) {
          this.orderPacker = order.packer.id;
        }

        if (order.delivery) {
          if (order.delivery.delivery_date) {
            const deliveryDateObj = new Date(order.delivery.delivery_date);
            this.deliveryDate = deliveryDateObj.toISOString().split("T")[0];
          }

          this.deliveryStatus = order.delivery.status;
          this.originalDeliveryStatus = order.delivery.status;
        } else {
          if (order.delivery_date) {
            const deliveryDateObj = new Date(order.delivery_date);
            this.deliveryDate = deliveryDateObj.toISOString().split("T")[0];
          }
        }

        this.deliveryAddress = order.address_detail.address_line_1;

        if (order.address_detail.address_line_2) {
          this.deliveryAddress += `, ${order.address_detail.address_line_2}`;
        }

        if (order.address_detail.address_line_3) {
          this.deliveryAddress += `, ${order.address_detail.address_line_3}`;
        }

        this.orderDetails = Object.assign({}, order);

        this.orderProducts.splice(0);
        for (let i = 0; i < order.products.length; i++) {
          const tmpProduct = order.products[i];
          if (tmpProduct.pivot.replacement_set) {
            const productRes = await this.$http.get(
              `/products/${tmpProduct.pivot.replacement_product_id}`,
              this.sessionStore.getHttpConfig
            );
            if (!tmpProduct.pivot.fullProduct) {
              tmpProduct.pivot.fullProduct = {};
            }
            tmpProduct.pivot.fullProduct.name = productRes.data.data[0].name;
          }
          this.orderProducts.push(tmpProduct);
        }

        this.shopAddresses.splice(0);
        for (let i = 0; i < order.shop.address_details.length; i += 1) {
          this.shopAddresses.push(order.shop.address_details[i]);
        }

        this.orderNotes = order.notes;

        if (order.tags === "credit") {
          this.isCreditOrder = true;
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading the order details. Please try again later or contact support."
        );
      });

    this.fetchPackers();
  },
  watch: {
    "searchProduct.sync"(val) {
      val && val !== this.searchProduct.selected && this.searchProductApi(val);
    },
    "searchProduct.value"(val) {
      if (val) {
        const product = this.allSearchedProducts.find((e) => e.id == val);
        if (product) {
          const productAlreadyAdded = this.orderProducts.find(
            (e) => e.id == val
          );
          if (!productAlreadyAdded) {
            const tmpProduct = { ...product };
            tmpProduct.pivot.quantity = 1;
            this.orderProducts.push(tmpProduct);
          }
        }
      }
    },
    "searchAlternativeProduct.sync"(val) {
      val &&
        val !== this.searchAlternativeProduct.selected &&
        this.searchProductApi(val);
    },
  },
};
</script>
